import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = '/Payment';

export async function getOperators_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/Operators`, getReqConfig(cookies))
  );
}

export async function getPartners_req(cookies = {}) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/PartnerList`, getReqConfig(cookies))
  );
}

export async function getPartnersByOperator_req(cookies = {}, operatorId) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/PartnerByOperator?operatorID=${operatorId}`,
      getReqConfig(cookies)
    )
  );
}

export async function depositWithSantim_req(
  operatorID,
  amount,
  paymentPartner
) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/DepositSantim`,
      {
        operatorID,
        amount,
        // paymentPartner,
      },
      getReqConfig()
    )
  );
}

export async function depositWithKacha_req(amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/KachaDeposit`,
      {
        amount,
      },
      getReqConfig()
    )
  );
}

export async function sendKachaOTP_req(reference, otp) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/KachaOTP`,
      {
        reference,
        otp,
      },
      getReqConfig()
    )
  );
}

export async function deposit_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Deposit`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}
export async function deposit_with_otp_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/DepositOtp`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}
export async function sendPaymentOTP_req(
  operatorID,
  reference,
  paymentID,
  otp
) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/AuthorizeOtp`,
      {
        operatorID,
        reference,
        paymentID,
        otp,
      },
      getReqConfig()
    )
  );
}
export async function withdraw_req(
  operatorID,
  amount,
  paymentPartner,
  accountNumber,
  accountName
) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Withdraw`,
      {
        operatorID,
        amount,
        paymentPartner,
        accountName,
        accountNumber,
      },
      getReqConfig({})
    )
  );
}

export async function getDepositTexts_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/DepositHeader`, getReqConfig(cookies))
  );
}
